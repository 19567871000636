import { Component } from "react";

class Header extends Component
{
    render() {
        return <div class="header">
            <img class="logo" src="favicon-32x32.png" />
            <span class="title">OpenXML Converter</span>
        </div>;
    }
}

export default Header;