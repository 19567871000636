import FourOFourPage from "../Pages/404";
import IndexPage from "../Pages/Index";
import LoginPage from "../Pages/Login";
import StylesPage from "../Pages/Styles";

class Router {
  static Routes = {
    login: {
      __component: LoginPage,
    }, //login page
    styles: {
      __component: StylesPage
    },
    "{param}": {
      __alias: "p1",
      __component: IndexPage,
    },
    __component: IndexPage, //default route
  };

  static Resolve() {
    const parts = window.location.pathname.split("/").filter((el) => !!el);
    let routes = Router.Routes;
    let params = {};
    for (let i = 0; i < parts.length; i++) {
      if (!!routes[parts[i]]) {
        routes = routes[parts[i]];
      } else if (!!routes["{param}"]) {
        routes = routes["{param}"];
        params[routes.__alias] = parts[i];
        console.dir(params);
        console.dir(routes);
      } else {
        routes = {
          __component: FourOFourPage,
        };
      }
    }
    return { component: routes.__component, params: params };
  }
}

export default Router;
