import { Component } from "react";
import Button from "../Components/Inputs/Button";
import Input from "../Components/Inputs/Input";
import Card from "../Components/Layout/Card";
import Grid from "../Components/Layout/Grid";
import Item from "../Components/Layout/Item";
import Link from "../Components/Link";
import NavBar from "../Components/Nav/NavBar";
import NavItem from "../Components/Nav/NavItem";
import Page from "../Components/Page";

class StylesPage extends Component {
  state = {};
  colors = ["primary", "primary-dark", "body", "body-accent", "accent", "highlight"];

  constructor() {
    super();
    const palette = {};
    this.colors.forEach((colName) => {
      palette[colName] = {
        background: this.fixColor(getComputedStyle(document.documentElement).getPropertyValue("--color-" + colName)),
        font: this.fixColor(getComputedStyle(document.documentElement).getPropertyValue("--color-font-" + colName)),
      };
    });

    this.state.palette = palette;
    console.dir(palette);
  }

  render() {
    return (
      <Page>
        <Card title="Navigation">
          <NavBar>
            <NavItem>Test</NavItem>
            <NavItem href="#testlink">Internal Link</NavItem>
            <NavItem href="#testlink" external>
              External Link
            </NavItem>
          </NavBar>
        </Card>
        <Card title="Links">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut condimentum dolor orci, non dapibus ligula pellentesque eget. Donec nec{" "}
            <Link>commodo</Link> nunc. Cras eget arcu pharetra, dapibus erat eget, cursus felis. Sed feugiat est metus, nec dictum augue pulvinar vel. Nunc
            dolor libero, fermentum ac ante non, porta semper ante. Nam ut nunc pulvinar, fringilla nunc eu, porttitor ante. Phasellus fringilla sed felis et
            consequat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Some test <Link>Link</Link> in between text and
            other <Link href="#testlink">Link</Link> and{" "}
            <Link href="#testlink" external>
              External Link
            </Link>
          </p>
        </Card>
        <Card title="Palette Editor">
          <Grid cols={3}>
            {this.colors.map((colName) => {
              const { background, font } = this.state.palette[colName];
              return (
                <Item span="1" style={{ border: "solid 1px" }}>
                  <p>
                    <Input
                      type="color"
                      name="bg"
                      value={background}
                      label="Background"
                      onChange={(e) => {
                        let palette = this.state.palette;
                        palette[colName]["background"] = e.target.value;
                        this.setState({ palette: palette });
                      }}
                    />
                  </p>
                  <p>
                    <Input
                      type="color"
                      name="txt"
                      value={font}
                      label="Text"
                      onChange={(e) => {
                        let palette = this.state.palette;
                        palette[colName]["font"] = e.target.value;
                        this.setState({ palette: palette });
                      }}
                    />
                  </p>
                  <div style={{ width: "100%", height: "100px", backgroundColor: background, color: font, fontSize: "1.5em" }}>{colName}</div>
                </Item>
              );
            })}
          </Grid>
          <p>
            <Button
              label="Apply Palette"
              onClick={this.applyPallette}
            />
          </p>
          <Grid cols={2}>
            <Item span={1}>
                <h3>Copy CSS from here to save it</h3>
              <textarea
                style={{ width: "100%", height: "190px" }}
                value={this.printPalette()}
              />
            </Item>
            <Item span={1}>
                <h3>Put CSS here to import it</h3>
              <textarea style={{ width: "100%", height: "190px" }} onChange={e=>this.importPalette(e.target.value)}/>
            </Item>
          </Grid>
        </Card>
      </Page>
    );
  }

  fixColor = (color) => {
    if (color.length == 4) {
      return (
        "#" + 
        color.substring(1, 1) + 
        color.substring(1, 1) + 
        color.substring(2, 1) + 
        color.substring(2, 1) + 
        color.substring(3, 1) + 
        color.substring(3, 1)
      );
    } else if (color.length == 5) {
      return (
        "#" +
        color.substring(1, 1) +
        color.substring(1, 1) +
        color.substring(2, 1) +
        color.substring(2, 1) +
        color.substring(3, 1) +
        color.substring(3, 1) +
        color.substring(4, 1) +
        color.substring(4, 1)
      );
    }
    return color;
  };

  importPalette = (css) => {
    const lines = css.split("\n").filter(x=>!!x);
    const palette = this.state.palette;
    lines.forEach(line=>{
        const parts = line.match(/--color-(?<color>[a-z\-]+)\s*\:\s*(?<value>\#[a-f\d]{3,8})/i).groups;
        if(parts&&parts.color&&parts.value){
            if(this.colors.indexOf(parts.color)>-1){
                palette[parts.color]['background'] = this.fixColor(parts.value);
            } else if(parts.color.startsWith('font-')) {
                const color = parts.color.substring(5); 
                palette[color]['font'] = this.fixColor(parts.value);
            }
        }
    });
    this.setState({palette});
    this.applyPallette();
  }

  printPalette = () => {
    return this.colors
    .map(
      (colName) =>
        "--color-" +
        colName +
        ": " +
        this.state.palette[colName]["background"] +
        "; \n" +
        "--color-font-" +
        colName +
        ": " +
        this.state.palette[colName]["font"] +
        ";"
    )
    .join("\n");
  }
  
  applyPallette = () => {
    this.colors.forEach((colName) => {
        document.documentElement.style.setProperty("--color-" + colName, this.state.palette[colName]["background"]);
        document.documentElement.style.setProperty("--color-font-" + colName, this.state.palette[colName]["font"]);
    });
  }
}

export default StylesPage;
