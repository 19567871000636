import { Component } from "react";

class NavBar extends Component {
  render() {
    const { children } = this.props;
    return <div class="nav">
        {children}
    </div>;
  }
}

export default NavBar;
