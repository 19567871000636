import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Router from './Helpers/Router';

function App() {
  const page = Router.Resolve();
  return <page.component params={page.params} />;
}

export default App;
