import { Component } from "react";
import Card from "../Components/Layout/Card";
import Page from "../Components/Page";

class IndexPage extends Component {
  render() {
    const { params } = this.props;
    return (
      <Page>
        <Card>Index</Card>
      </Page>
    );
  }
}

export default IndexPage;
