import { Component } from "react";

class Grid extends Component {
  render() {
    const { cols, children, style } = this.props;
    return <div style={style} class={"grid g-"+(cols||6)}>{children}</div>;
  }
}

export default Grid;
