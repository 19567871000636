import { Component } from "react";

class Footer extends Component
{
    render() {
        let year = 2023;
        if(new Date().getFullYear()>year) year = year+" - "+new Date().getFullYear();
        return <div class="footer">
            ©{year} AKKADev (Vladyslav Aksonov Private Entrepreneur)
        </div>;
    }
}

export default Footer;