import { Component } from "react";

class Item extends Component {
  render() {
    const { span, children, style } = this.props;
    return <div style={style} class={"item s-"+(span||6)}>{children}</div>;
  }
}

export default Item;
