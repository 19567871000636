import { Component } from "react";

class Button extends Component {
  render() {
    const { style, label, onClick } = this.props;
    return <input type="button" style={style} onClick={onClick} value={label} />;
  }
}

export default Button;
