import { Component } from "react";
import Link from "../Link";

class NavItem extends Component
{
    render() {
        return <Link {...this.props} />;
    }
}

export default NavItem;