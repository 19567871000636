import { Component } from "react";

class Link extends Component
{
    render() {
        const {children, href, external} = this.props;
        return <a href={href||'#'} target={external?'blank':''}>{children}{external?'🔗':''}</a>;
    }
}

export default Link;