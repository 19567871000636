import { Component } from "react";

class Card extends Component {
  render() {
    const { children, title } = this.props;
    return <div class="card">{title?(<h2 class="title">{title}</h2>):""}<div class="content">{children}</div></div>;
  }
}

export default Card;
