import { Component } from "react";

class Input extends Component {
  render() {
    const { style, label, type, value, name, onChange } = this.props;
    return (
      <label class="input">
        {label}: <input style={style} type={type} value={value} name={name} onChange={onChange}/>
      </label>
    );
  }
}

export default Input;
