import { Component, Fragment } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import NavBar from "./Nav/NavBar";
import NavItem from "./Nav/NavItem";

class Page extends Component {
  render() {
    const { children } = this.props;
    return <Fragment>
        <Header />
        <NavBar>
            <NavItem href="/member">Member area</NavItem>
            <NavItem href="/doc">Documentation</NavItem>
            <NavItem href="/playground">Playground</NavItem>
          </NavBar>
        <Footer />
        <div class="body">{children}</div>
    </Fragment>;
  }
}

export default Page;
